<template>
  <div>

    <b-card
      no-body
      class="mb-0"
    >
      <titulo-card-com-voltar
        :voltar="registroAnterior"
        :titulo="tituloCard()"
      />

      <div class="m-2 px-2">
        <b-row>
          <b-col
            cols="9"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Descrição:
            </div>
            <div>{{ itemData && itemData.sub_tipo.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="3"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Alterar status para:
            </div>
            <div>
              <select-com-pesquisa
                ref="selectStatus"
                :itens-para-edicao="[]"
                :multiple="false"
                placeholder="Selecione um status"
                valor-name-select="nome"
                url="/status-registros"
                @selecionados="tratarEventoFiltroStatus"
              />
            </div>
          </b-col>
          <b-col
            cols="4"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Localização:
            </div>
            <div>{{ itemData && itemData.localizacao && itemData.localizacao.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="3"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Tipo:
            </div>
            <div>{{ itemData && itemData.sub_tipo && itemData.sub_tipo.tipo.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="font-weight-bold">
              Status:
            </div>
            <div>{{ itemData && itemData.status && itemData.status.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="4"
          >
            <div class="font-weight-bold">
              Registrado por:
            </div>
            <div>{{ itemData && itemData.registrado_por.name || '-' }}</div>
          </b-col>
          <b-col
            cols="8"
          >
            <div class="font-weight-bold">
              Registro em:
            </div>
            <div>{{ itemData && mascaraDataBr(itemData.created_at) || '-' }}</div>
          </b-col>
          <b-col
            cols="4"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Aprovador Por:
            </div>
            <div>{{ itemData && itemData.aprovado_por && itemData.aprovado_por.name || '-' }}</div>
          </b-col>
          <b-col
            cols="8"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Aprovador em:
            </div>
            <div>{{ itemData && mascaraDataBr(itemData.aprovado_em) }}</div>
          </b-col>
          <b-col
            cols="9"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Observação do registro:
            </div>
            <div>{{ itemData && itemData.descricao || '-' }}</div>
          </b-col>
          <b-col
            cols="6"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Título Abreviado (p/ relatório):
            </div>
            <div>
              <b-form-input
                v-model="itemData && itemData.titulo_abreviado"
                type="text"
                placeholder="Informe o título abreviado do registro para o relatório"
              />

            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Apontamento:
            </div>
            <textarea
              v-model="itemData && itemData.observacoes"
              class="form-control"
              rows="3"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="tratarEventoFiltroStatus(itemData)"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card
            v-if="itemData && itemData.imagens"
            class="px-2"
          >
            <div class="font-weight-bold font-medium-2 mb-2 border-bottom">
              Imagens Registradas ({{ itemData.imagens.length }})
            </div>
            <b-card-text>
              <b-row>
                <b-col
                  v-for="(imagem, index) in itemData.imagens"
                  :key="index"
                  cols="12"
                  md="6"
                  lg="4"
                  class="mb-2"
                >
                  <b-card
                    no-body
                    class="mb-0"
                  >
                    <b-img
                      :src="imagem.url"
                      fluid
                      thumbnail
                    />
                  </b-card>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BFormInput, BImg, BRow,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'

import { mascaraDataBr } from '@/@core/utils/filter'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import useItemList from '@/views/apps/registros/list/useItemList'
import userStoreModule from '../registroStoreModule'

export default {
  components: {
    BFormInput,
    BButton,
    TituloCardComVoltar,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,
    SelectComPesquisa,
  },
  setup() {
    // const store = useStore()

    const itemData = ref(null)
    const statusFilter = ref(null)
    const registroAnterior = ref(null)

    const REGISTRO_APP_STORE_MODULE_NAME = 'app-registros'

    // Register module
    if (!store.hasModule(REGISTRO_APP_STORE_MODULE_NAME)) store.registerModule(REGISTRO_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REGISTRO_APP_STORE_MODULE_NAME)) store.unregisterModule(REGISTRO_APP_STORE_MODULE_NAME)
    })

    const {
      alterarStatus,
    } = useItemList()

    registroAnterior.value = `obras/registros/${router.currentRoute.params.idObra}`

    const tratarEventoFiltroStatus = itens => {
      statusFilter.value = itens && itens.id

      alterarStatus({
        idObra: router.currentRoute.params.idObra,
        status: itens.id || itemData.value.status.id,
        idRegistro: itemData.value.id,
        observacoes: itemData.value.observacoes,
        titulo_abreviado: itemData.value.titulo_abreviado,

      })
    }

    store.dispatch('app-registros/fetchRegistro', {
      idObra: router.currentRoute.params.idObra,
      idRegistro: router.currentRoute.params.idRegistro,
    })
      .then(response => {
        itemData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          itemData.value = undefined
        }
      })
    const tituloCard = () => {
      if (itemData.value) {
        return `${itemData.value.sub_tipo.nome} - ${itemData.value.localizacao.nome}`
      }
      return 'Descrição não informada'
    }

    return {
      itemData,
      tituloCard,
      tratarEventoFiltroStatus,
      registroAnterior,
      mascaraDataBr,

    }
  },
}
</script>
